import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  CustomAlert,
  Typography,
  AddIcon,
  AddCircleOutlineIcon,
  LoadingButton,
  Grid,
} from "@enerbit/base";
import Header from "../../components/Header/Header";
import { useParams } from "react-router-dom";
import ContactsTable from "../../components/ContactsTable/ContactsTable";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { getLocationDetail, updateLocation } from "../../store/thunks/locations";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { ExtendFormValues, FormValues, protocolObj } from "../../const";
import ProtocolItem from "../../components/ProtocolItem/ProtocolItem";
import AddContactModal from "../../components/AddContactModal/AddContactModal";
import { Protocol } from "../../models/location";
import { enqueueSnackbar } from "notistack";

const LocationDetail = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [detailError, setDetailError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const dispacth = useDispatch<AppDispatch>();
  const { id } = useParams();

  const form = useForm<ExtendFormValues>();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = form;

  const {
    fields: contacts,
    append: addContact,
    remove: removeContact,
    update: updateContact,
  } = useFieldArray({ name: "contacts", control, keyName: "_id" });

  const {
    fields: protocols,
    append: addProtocol,
    remove: removeProtocol,
  } = useFieldArray({ name: "protocols", control, keyName: "_id" });

  const getDetail = async () => {
    if (!id) return;

    setLoading(true);
    setDetailError(false);
    setSuccess(false);

    dispacth(getLocationDetail(id))
      .then((res) => {
        const data: FieldValues = res.payload as FormValues;
        const fields: any[] = ["contacts", "location", "protocols"];
        fields.forEach((field) => setValue(field, data[field]));
      })
      .catch(() => setDetailError(true))
      .finally(() => setLoading(false));
  };

  const onSave = (data: ExtendFormValues) => {
    if (!id) return;

    setLoadingSave(true);
    setError(false);

    dispacth(updateLocation({ data, id }))
      .then((res) => {
        protocols.forEach(() => removeProtocol(0));
        const newData = res.payload as Protocol[];
        newData.forEach((protocol: Protocol) => addProtocol(protocol));
        setSuccess(true);
        enqueueSnackbar("Datos guardados existosamente.", { variant: "success" });
      })
      .catch(() => {
        setError(true);
        enqueueSnackbar("Error al guardar. Inténtalo de nuevo", { variant: "error" });
      })
      .finally(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "auto", // O 'auto' para un desplazamiento instantáneo
        });
        setLoadingSave(false);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSave)}>
        <Header isDetail={true} />
        {loading && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 2 }}>
            <CircularProgress />
          </Box>
        )}
        {detailError && (
          <CustomAlert
            severity='error'
            onClose={() => setDetailError(false)}
            text={"Error al cargar los datos de la locación."}
          />
        )}
        {id && !loading && !error && (
          <Box>
            <Box my={2}>
              <Typography color={"primary"} sx={{ fontSize: "34px", fontWeight: 700, my: 2 }}>
                {watch("location.name")}
              </Typography>
              <Typography color={"primary"} sx={{ fontSize: "24px" }} mb={1}>
                {watch("location.address")}
              </Typography>
              <Grid container mb={3}>
                <Grid item xs={10}>
                  <Box>
                    <Typography color={"primary"} sx={{ fontWeight: 700 }}>
                      Descripción
                    </Typography>
                    <Typography sx={{ textAlign: "justify" }}>
                      {watch("location.description")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container xs={2} alignItems='center' justifyContent='center'>
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                      onClick={() => setOpenModal(true)}
                      variant='contained'
                      color='secondary'>
                      <AddIcon />
                      Agregar contacto
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <ContactsTable
                watch={watch}
                contacts={contacts}
                removeContact={removeContact}
                control={control}
                editable={true}
                updateContact={updateContact}
              />
              <Box my={2}>
                <Typography color={"primary"} sx={{ fontSize: "22px" }}>
                  Protocolos
                </Typography>
                <Box my={1}>
                  {protocols.map((protocol, index) => (
                    <ProtocolItem
                      key={protocol._id}
                      protocol={protocol}
                      index={index}
                      removeProtocol={removeProtocol}
                      loading={loadingSave}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      isEdit={true}
                    />
                  ))}
                </Box>
                <Button
                  fullWidth
                  variant='outlined'
                  onClick={() => addProtocol(protocolObj)}
                  sx={{ height: "41px", my: 3, display: "flex", alignItems: "center", gap: 1 }}>
                  <AddCircleOutlineIcon />
                  <Typography color={"primary"} fontWeight={700}>
                    Agregar protocolo
                  </Typography>
                </Button>
              </Box>
            </Box>
            <LoadingButton
              fullWidth
              variant='contained'
              color='secondary'
              type='submit'
              loading={loadingSave}>
              Guardar
            </LoadingButton>
          </Box>
        )}
      </form>
      <AddContactModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        addContacts={addContact}
        locationId={id}
      />
    </>
  );
};

export default LocationDetail;
