import * as yup from "yup";

const locationSchema = yup.object().shape({
  name: yup.string().required("Este campo es requerido"),
  description: yup.string().optional(),
  address: yup.string().required("Este campo es requerido"),
});

const protocolSchema = yup.array().of(
  yup.object().shape({
    name: yup.string().required("Este campo es requerido"),
    description: yup.string().optional(),
    // stages: yup.array().of(
    //   yup.object().shape({
    //     name: yup.string().required("Este campo es requerido"),
    //     description: yup.string().optional(),
    //   })
    // ),
  })
);

export const contactsSchema = yup.array().of(
  yup.object().shape({
    name: yup.string().required("Este campo es requerido"),
    description: yup.string().optional(),
    phone: yup
      .string()
      .required("Este campo es requerido")
      .matches(/^\d{7,}$/, "Número de teléfono inválido"),
    email: yup
      .string()
      .required("Este campo es requerido")
      .matches(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, "Correo inválido"),
    role: yup.string().required("Este campo es requerido"),
  })
);

export const schema = yup
  .object()
  .shape({ location: locationSchema, protocols: protocolSchema, contacts: contactsSchema });
