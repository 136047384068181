import { DOCUMENTS_LIST } from "../const";
import { Protocol, ProtocolDocument, Stages } from "../models/location";

export const generateId = () => {
  const id = Math.random().toString(36).substring(2);

  return id;
};

export const formatProtocolResponse = (protocols: Protocol[]) => {
  const formatedProtocols = protocols.map((item: any) => {
    const documents: ProtocolDocument[] = [];
    if (item.documents) {
      item.documents.map((doc: string) => {
        const index = DOCUMENTS_LIST.findIndex((tempDoc) => tempDoc.value === doc);

        if (index !== -1) {
          documents.push(DOCUMENTS_LIST[index]);
        }
      });
    }

    return {
      id: item.id,
      name: item.name,
      description: item.description,
      stages: item.stages.map((stage: Stages) => ({ ...stage, previousExists: true })),
      documents,
    };
  });

  return formatedProtocols;
};
