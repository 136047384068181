import { hot } from "react-hot-loader/root";
import Router from "./router/Router";
import { ThemeConfig, initEnviroment, NotiProvider } from "@enerbit/base";
import { Provider } from "react-redux";
import { store } from "./store/store";
import MainLayout from "./layout/MainLayout";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export default hot(function Root() {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <NotiProvider>
          <MainLayout>
            <Router />
          </MainLayout>
        </NotiProvider>
      </ThemeConfig>
    </Provider>
  );
});
