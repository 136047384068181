import { Box, CircularProgress, CustomAlert, Pagination, Stack } from "@enerbit/base";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getLocations } from "../../store/thunks/locations";
import { useEffect } from "react";
import LocationItem from "../../components/LocationItem/LocationItem";
import { setCurrentPage, setError } from "../../store/slices/location";
import { PaginationContainer } from "../../common/PaginationContainer";
import { ListDivisor } from "../../common/ListDivisor";

const LoactionsList = () => {
  const loadingLocations = useSelector((state: RootState) => state.locations.loading);
  const errorLoading = useSelector((state: RootState) => state.locations.error);
  const locations = useSelector((state: RootState) => state.locations.locations);
  const totalPages = useSelector((state: RootState) => state.locations.totalPages);
  const currentPage = useSelector((state: RootState) => state.locations.currentPage);

  const dispatch = useDispatch<AppDispatch>();

  const _getLocations = async () => {
    await dispatch(getLocations());
  };

  useEffect(() => {
    _getLocations();
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    event.preventDefault();
    dispatch(setCurrentPage(value - 1));
    dispatch(getLocations());
  };

  return (
    <Box>
      <Header isDetail={false} />
      {!loadingLocations && !errorLoading && (
        <Box my={3}>
          {locations.map((location) => (
            <Box sx={{ height: "280px" }} key={location.id}>
              <ListDivisor />
              <LocationItem location={location} />
            </Box>
          ))}
        </Box>
      )}
      {loadingLocations && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", my: 3 }}>
          <CircularProgress />
        </Box>
      )}
      {errorLoading && (
        <Box my={2}>
          <CustomAlert
            severity='error'
            onClose={() => dispatch(setError(false))}
            text='Hubo un error al cargar las locaciones'
          />
        </Box>
      )}
      {!loadingLocations && (
        <PaginationContainer>
          <Stack spacing={2}>
            <Pagination
              page={currentPage + 1}
              count={totalPages}
              shape='rounded'
              onChange={handleChange}
            />
          </Stack>
        </PaginationContainer>
      )}
    </Box>
  );
};

export default LoactionsList;
