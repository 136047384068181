import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DeleteOutlineIcon,
  EditOutlinedIcon,
  Button,
} from "@enerbit/base";
import "./ContactsTable.css";
import { ContactsSchema } from "../../models/location";
import moment from "moment";
import { useEffect, useState } from "react";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { deleteContacts } from "../../services";
import { Control, UseFieldArrayRemove, UseFieldArrayUpdate, UseFormWatch } from "react-hook-form";
import EditContactModal from "../EditContactModal/EditContactModal";
import { CONTACTS_TABLE_HEADERS, ExtendFormValues } from "../../const";

interface Props {
  contacts: ContactsSchema[];
  removeContact?: UseFieldArrayRemove;
  control?: Control<ExtendFormValues, any>;
  editable?: boolean;
  watch?: UseFormWatch<ExtendFormValues>;
  updateContact?: UseFieldArrayUpdate<ExtendFormValues, "contacts">;
}

const ContactsTable = ({
  contacts,
  removeContact,
  control,
  editable,
  watch,
  updateContact,
}: Props) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<ContactsSchema>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [tableHeaders, setTableHeaders] = useState<string[]>(CONTACTS_TABLE_HEADERS);

  useEffect(() => {
    setTableHeaders((pd) => {
      const nD = [...pd];
      if (editable) {
        nD.push("Eliminar");
        nD.push("Editar");
      }
      return nD;
    });
  }, []);

  const [editModal, setEditModal] = useState<boolean>(false);

  const onDelete = async (contact: ContactsSchema | undefined, index: number | undefined) => {
    if (!contact || !contact.id || index === undefined) return;

    setLoading(true);
    setError(false);

    try {
      await deleteContacts(contact.id);

      if (removeContact) {
        removeContact(index);
      }

      setDeleteModal(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none !important" }}>
        <TableContainer sx={{ maxHeight: 255 }}>
          <Table
            stickyHeader
            sx={{
              ".MuiTableRow-root:nth-child(even)": { bgcolor: "#efe9fb" },
              ".MuiTableRow-root th": {
                bgcolor: "#f2f4f7 !important",
                padding: "padding: 8px 16px !important",
                color: "color: #667085 !important",
                fontSize: "14px",
              },
              ".MuiTableCell-root": {
                borderBottom: "unset !important",
              },
              ".MuiInputBase-root": {
                bgcolor: "white",
                borderRadius: "12px !important",
              },
            }}>
            <TableHead sx={{ ".MuiTableCell-root": { padding: "7px !important" } }}>
              <TableRow>
                {tableHeaders.map((name, index) => (
                  <TableCell key={index}>{name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ ".MuiTableCell-root": { padding: "7px !important" } }}>
              {contacts.map((contact, index) => (
                <TableRow
                  key={contact.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell>{contact.phone}</TableCell>
                  <TableCell>{contact.role}</TableCell>
                  <TableCell>{contact.email}</TableCell>
                  <TableCell>{contact.description}</TableCell>
                  <TableCell>{moment(contact.created_at).format("DD/MM/YYYY")}</TableCell>
                  {editable && (
                    <TableCell>
                      <Button
                        sx={{ color: "#3D4350" }}
                        onClick={() => {
                          setSelectedContact(contact);
                          setSelectedIndex(index);
                          setDeleteModal(true);
                        }}>
                        <DeleteOutlineIcon sx={{ color: "#3D4350" }} />
                      </Button>
                    </TableCell>
                  )}
                  {editable && (
                    <TableCell>
                      <Button
                        sx={{ color: "#3D4350" }}
                        onClick={() => {
                          setSelectedIndex(index);
                          setEditModal(true);
                        }}>
                        <EditOutlinedIcon sx={{ color: "#3D4350" }} />
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ConfirmationModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        onConfirm={() => onDelete(selectedContact, selectedIndex)}
        title='¿Seguro que deseas eliminar este contacto? Esta acción no se puede deshacer'
        errorMessage='Error eliminando el contacto'
        loading={loading}
        error={error}
        setError={setError}
      />
      {control && watch && updateContact && (
        <EditContactModal
          contacts={contacts}
          open={editModal}
          handleClose={() => setEditModal(false)}
          index={selectedIndex}
          control={control}
          watch={watch}
          updateContact={updateContact}
        />
      )}
    </>
  );
};

export default ContactsTable;
