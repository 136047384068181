import AppsIcon from "@mui/icons-material/Apps";
import { Box, Button, DeleteOutlineIcon, FormControl, Grid, TextField } from "@enerbit/base";
import { ExtendFormValues, FormValues } from "../../const";
import { Control, Controller, FieldErrors, UseFieldArrayRemove } from "react-hook-form";
import { DraggableProvided } from "react-beautiful-dnd";
import { Protocol, Stages } from "../../models/location";
import { useState } from "react";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { deleteStage } from "../../services";

interface Props {
  protocolIndex: number;
  loading: boolean;
  control: Control<ExtendFormValues, any>;
  stageIndex: number;
  removeStage: UseFieldArrayRemove;
  provided: DraggableProvided;
  errors: FieldErrors<ExtendFormValues>;
  protocol: Protocol;
  stages: Stages[];
  isEdit?: boolean;
}

const ProtocolStageItem = ({
  protocolIndex,
  control,
  loading,
  stageIndex,
  removeStage,
  provided,
  protocol,
  stages,
  isEdit,
}: Props) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onDeleteStage = async () => {
    if (stages[stageIndex].previousExists && protocol.id) {
      try {
        setLoadingDelete(true);
        setError(false);
        await deleteStage(protocol.id, stages[stageIndex].id);
        removeStage(stageIndex);
        setDeleteModal(false);
      } catch (error) {
        setError(true);
      } finally {
        setLoadingDelete(false);
      }
    } else {
      removeStage(stageIndex);
    }
  };

  return (
    <>
      <Grid container columnSpacing={2} mt={2}>
        <Grid
          item
          xs={0.5}
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {(!isEdit || stages.every((stage) => !stage.previousExists)) && (
            <Box
              {...provided.dragHandleProps}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "25px",
                cursor: "grabbing",
              }}>
              <AppsIcon sx={{ color: "#A3A9B6" }} />
            </Box>
          )}
        </Grid>
        <Grid item xs={5.5}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`protocols.${protocolIndex}.stages.${stageIndex}.name`}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={loading}
                  InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5.5}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`protocols.${protocolIndex}.stages.${stageIndex}.description`}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={loading}
                  InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={0.5}>
          <Button
            disabled={loading}
            fullWidth
            onClick={() => setDeleteModal(true)}
            sx={{ backgroundColor: "#FEECEB", width: "40px", height: "50px", minWidth: "unset" }}>
            <DeleteOutlineIcon color='error' />
          </Button>
        </Grid>
      </Grid>
      <ConfirmationModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        onConfirm={() => onDeleteStage()}
        title='¿Seguro que deseas eliminar este paso? Esta acción no se puede deshacer'
        errorMessage='Error eliminando el paso'
        loading={loadingDelete}
        error={error}
        setError={setError}
      />
    </>
  );
};

export default ProtocolStageItem;
