import { useNavigate } from "react-router-dom";
import { LocationSchema } from "../../models/location";
import { Box, Button, Grid, Typography, styled } from "@enerbit/base";
import ContactsTable from "../ContactsTable/ContactsTable";

interface Props {
  location: LocationSchema;
}

const LocationItem = ({ location }: Props) => {
  const navigate = useNavigate();

  return (
    <Grid container mb={2}>
      <Grid item xs={4}>
        <MainContainer>
          <Typography color={"primary"} sx={{ fontWeight: 700, fontSize: "20px" }}>
            {location.name}
          </Typography>
          <Typography color={"primary"} sx={{ fontSize: "16px" }} my={1}>
            {location.address}
          </Typography>
          <Box sx={{ width: "100%", overflowY: "auto", maxHeight: "100px" }}>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#3D4350",
                textAlign: "justify",
                paddingRight: "10px",
              }}
              my={1}>
              {location.description}
            </Typography>
          </Box>
          <Box>
            <Button
              variant='contained'
              sx={{ mt: 2 }}
              color='secondary'
              onClick={() => navigate(`/detail/${location.id}`)}>
              Ver detalle
            </Button>
          </Box>
        </MainContainer>
      </Grid>
      <Grid item xs={8}>
        <ContactsTable contacts={location.contacts} />
      </Grid>
    </Grid>
  );
};

export default LocationItem;

const MainContainer = styled("div")`
  height: 212px;
  padding: 24px;
`;
