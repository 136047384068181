import {
  AddCircleOutlineIcon,
  Box,
  Button,
  CloseIcon,
  CustomAlert,
  DeleteOutlineIcon,
  FormControl,
  FormHelperText,
  Grid,
  LoadingButton,
  Modal,
  TextField,
  Typography,
  styled,
} from "@enerbit/base";
import {
  Controller,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { ContactsForm, ExtendFormValues, contactObj } from "../../const";
import { useEffect, useState } from "react";
import { createContactsBulk } from "../../services";
import { ContactsSchema } from "../../models/location";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactsSchema } from "../../const/validations";
import * as yup from "yup";

interface Props {
  open: boolean;
  handleClose: () => void;
  addContacts: UseFieldArrayAppend<ExtendFormValues, "contacts">;
  locationId: string | undefined;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
  width: "1135px",
};

const HEADERS: string[] = [
  "Nombre",
  "Celular - teléfono",
  "Correo electrónico",
  "Descripción",
  "Detalle - rol - información",
];

const AddContactModal = ({ open, handleClose, addContacts, locationId }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const form = useForm<ContactsForm>({
    defaultValues: { contacts: [{ description: "", name: "", phone: "", role: "", email: "" }] },
    resolver: yupResolver(yup.object().shape({ contacts: contactsSchema })),
  });
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = form;

  const { fields: tempContacts, append, remove } = useFieldArray({ name: "contacts", control });

  const onClose = () => {
    setValue("contacts", [{ description: "", name: "", phone: "", role: "", email: "" }]);
    handleClose();
  };

  const onSave = async (data: ContactsForm) => {
    if (!locationId || Object.keys(errors).length > 0) return;

    setLoading(true);
    setError(false);

    try {
      await createContactsBulk(data.contacts, locationId);
      data.contacts.map((item) => addContacts(item));
      onClose();
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSave)}>
        <Box sx={style}>
          <Box
            sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", my: 2 }}>
            <Typography sx={{ color: "#667085", fontSize: "18px" }}>
              Agregar contacto (s)
            </Typography>
            <Button onClick={onClose} variant='outlined' className='back-btn'>
              <CloseIcon />
            </Button>
          </Box>
          <Grid container columnSpacing={2} mt={2}>
            {HEADERS.map((item, index) => (
              <Grid key={index} item xs={2.2}>
                <TableHeaders>
                  <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{item}</Typography>
                </TableHeaders>
              </Grid>
            ))}
          </Grid>
          {tempContacts.map((item, index) => (
            <Grid key={item.id} container columnSpacing={2} mt={2}>
              <Grid item xs={2.2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`contacts.${index}.name`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        disabled={loading}
                        error={
                          !!errors.contacts &&
                          !!errors.contacts[index] &&
                          !!errors.contacts[index]?.name
                        }
                        InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                        fullWidth
                      />
                    )}
                  />
                  {!!errors.contacts &&
                    !!errors.contacts[index] &&
                    !!errors.contacts[index]?.name && (
                      <FormHelperText error>{errors.contacts[index]?.name?.message}</FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={2.2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`contacts.${index}.phone`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        disabled={loading}
                        error={
                          !!errors.contacts &&
                          !!errors.contacts[index] &&
                          !!errors.contacts[index]?.phone
                        }
                        InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                        fullWidth
                      />
                    )}
                  />
                  {!!errors.contacts &&
                    !!errors.contacts[index] &&
                    !!errors.contacts[index]?.phone && (
                      <FormHelperText error>
                        {errors.contacts[index]?.phone?.message}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={2.2}>
                <FormControl>
                  <Controller
                    control={control}
                    name={`contacts.${index}.email`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        disabled={loading}
                        error={
                          !!errors.contacts &&
                          !!errors.contacts[index] &&
                          !!errors.contacts[index]?.email
                        }
                        InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                        fullWidth
                      />
                    )}
                  />
                  {!!errors.contacts &&
                    !!errors.contacts[index] &&
                    !!errors.contacts[index]?.email && (
                      <FormHelperText error>
                        {errors.contacts[index]?.email?.message}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={2.2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`contacts.${index}.description`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        disabled={loading}
                        error={
                          !!errors.contacts &&
                          !!errors.contacts[index] &&
                          !!errors.contacts[index]?.description
                        }
                        InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                        fullWidth
                      />
                    )}
                  />
                  {!!errors.contacts &&
                    !!errors.contacts[index] &&
                    !!errors.contacts[index]?.description && (
                      <FormHelperText error>
                        {errors.contacts[index]?.description?.message}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={2.2}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name={`contacts.${index}.role`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        disabled={loading}
                        error={
                          !!errors.contacts &&
                          !!errors.contacts[index] &&
                          !!errors.contacts[index]?.role
                        }
                        InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                        fullWidth
                      />
                    )}
                  />
                  {!!errors.contacts &&
                    !!errors.contacts[index] &&
                    !!errors.contacts[index]?.role && (
                      <FormHelperText error>{errors.contacts[index]?.role?.message}</FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={0.8}>
                <Button
                  onClick={() => remove(index)}
                  fullWidth
                  disabled={loading}
                  sx={{
                    backgroundColor: "#FEECEB",
                    width: "50px",
                    height: "50px",
                    minWidth: "unset",
                  }}>
                  <DeleteOutlineIcon color='error' />
                </Button>
              </Grid>
            </Grid>
          ))}
          {error && (
            <Box my={1}>
              <CustomAlert
                severity='error'
                onClose={() => setError(false)}
                text={"Error al crear los contactos"}
              />
            </Box>
          )}
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              fullWidth
              disabled={loading}
              variant='outlined'
              onClick={() => append(contactObj)}
              sx={{ height: "41px", my: 3, display: "flex", alignItems: "center", gap: 1 }}>
              <AddCircleOutlineIcon />
              <Typography color={"primary"} fontWeight={700}>
                Agregar contacto
              </Typography>
            </Button>
            <LoadingButton
              fullWidth
              loading={loading}
              variant='contained'
              color='secondary'
              type='submit'
              sx={{ height: "41px", my: 3, display: "flex", alignItems: "center", gap: 1 }}>
              <Typography fontWeight={700}>Guardar</Typography>
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};

export default AddContactModal;

const TableHeaders = styled("div")`
  height: 41px;
  width: 100%;
  background-color: #f2f4f7;
  border-radius: 8px;
  padding: 12px, 14px, 12px, 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
