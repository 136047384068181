import {
  AddCircleOutlineIcon,
  ArrowForwardIcon,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoadingButton,
  TextField,
  Typography,
  styled,
} from "@enerbit/base";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import ContactItem from "../../components/ContactItem/ContactItem";
import { useNavigate } from "react-router-dom";
import ProtocolItem from "../../components/ProtocolItem/ProtocolItem";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ExtendFormValues, FormValues, contactObj, protocolObj } from "../../const";
import "../../styles/styles.css";
import { createLocation } from "../../store/thunks/locations";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "../../const/validations";
import { useSelector } from "react-redux";
import { setError, setSuccess } from "../../store/slices/location";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";

const CONTACT_TABLE_HEADERS: string[] = [
  "Nombre",
  "Celular - teléfono",
  "Correo electrónico",
  "Descripción",
  "Detalle - rol - información",
];

const CreateLocation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const loading = useSelector((state: RootState) => state.locations.loading);

  useEffect(() => {
    dispatch(setError(false));
    dispatch(setSuccess(false));
  }, []);

  const form = useForm<ExtendFormValues>({
    defaultValues: {
      contacts: [contactObj],
      protocols: [protocolObj],
      location: {
        address: "",
        description: "",
        name: "",
        latitude: 0,
        longitude: 0,
      },
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = form;

  const {
    fields: contacts,
    append: addContact,
    remove: removeContact,
  } = useFieldArray({ name: "contacts", control });

  const {
    fields: protocols,
    append: addProtocol,
    remove: removeProtocol,
  } = useFieldArray({ name: "protocols", control, keyName: "_id" });

  const onSubmit = async (data: FormValues) => {
    await dispatch(createLocation(data));
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px", mb: 2 }}>
        <Box
          sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          onClick={() => navigate("/")}>
          <ArrowForwardIcon
            style={{ transform: "rotate(180deg)", fontSize: "35px" }}
            color='primary'
          />
        </Box>
        <Typography color={"primary"} sx={{ fontWeight: 700, fontSize: "28px" }}>
          Crear locación
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography color={"primary"} sx={{ fontSize: "22px" }}>
          Locación
        </Typography>
        <Grid container rowSpacing={2} sx={{ marginTop: "3px" }}>
          <Grid item xs={12}>
            <InputLabel className='title-input-edition' required>
              Nombre
            </InputLabel>
            <FormControl fullWidth>
              <Controller
                control={control}
                name='location.name'
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={loading}
                    error={!!errors.location?.name}
                    InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                    fullWidth
                  />
                )}
              />
              {!!errors.location?.name && (
                <FormHelperText error>{errors.location.name.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className='title-input-edition' required>
              Dirección
            </InputLabel>
            <FormControl fullWidth>
              <Controller
                control={control}
                name='location.address'
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={loading}
                    error={!!errors.location?.address}
                    InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                    fullWidth
                  />
                )}
              />
              {!!errors.location?.address && (
                <FormHelperText error>{errors.location.address.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className='title-input-edition'>Descripción</InputLabel>
            <Controller
              control={control}
              name='location.description'
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={loading}
                  multiline
                  rows={3}
                  InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Box my={2}>
          <Typography sx={{ color: "#667085", fontSize: "18px" }}>Agregar contacto (s)</Typography>
          <Grid container columnSpacing={2} mt={2}>
            {CONTACT_TABLE_HEADERS.map((item, index) => (
              <Grid key={index} item xs={2.2}>
                <TableHeaders>
                  <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{item}</Typography>
                </TableHeaders>
              </Grid>
            ))}
          </Grid>
          {contacts.map((field, index) => (
            <ContactItem
              key={field.id}
              control={control}
              loading={loading}
              index={index}
              removeContact={() => removeContact(index)}
              errors={errors}
            />
          ))}
          <Button
            fullWidth
            variant='outlined'
            onClick={() => addContact(contactObj)}
            sx={{ height: "41px", my: 3, display: "flex", alignItems: "center", gap: 1 }}>
            <AddCircleOutlineIcon />
            <Typography color={"primary"} fontWeight={700}>
              Agregar contacto
            </Typography>
          </Button>
        </Box>
        <Box my={2}>
          <Typography color={"primary"} sx={{ fontSize: "22px" }}>
            Protocolo
          </Typography>
          <Box my={1}>
            {protocols.map((protocol, index) => (
              <ProtocolItem
                protocol={protocol}
                key={protocol._id}
                index={index}
                removeProtocol={removeProtocol}
                loading={loading}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            ))}
          </Box>
          <Button
            fullWidth
            variant='outlined'
            onClick={() => addProtocol(protocolObj)}
            sx={{ height: "41px", my: 3, display: "flex", alignItems: "center", gap: 1 }}>
            <AddCircleOutlineIcon />
            <Typography color={"primary"} fontWeight={700}>
              Agregar protocolo
            </Typography>
          </Button>
        </Box>
        <ButtonsContainer>
          <LoadingButton
            loading={loading}
            fullWidth
            variant='contained'
            color='secondary'
            type='submit'>
            Guardar
          </LoadingButton>
        </ButtonsContainer>
      </form>
    </Box>
  );
};

export default CreateLocation;

const TableHeaders = styled("div")`
  height: 41px;
  width: 100%;
  background-color: #f2f4f7;
  border-radius: 8px;
  padding: 12px, 14px, 12px, 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;
