import { api } from "@enerbit/base";
import { ContactsSchema, Protocol, SimpleContactsSchema, Stages } from "../models/location";

export const createContactsBulk = async (contacts: SimpleContactsSchema[], locationId: string) => {
  const requests = contacts.map(async (contact) => {
    const data = { ...contact, location_id: locationId };
    await api.post("/admin-directory/v1/contacts/", data);
  });

  await Promise.all(requests);
};

export const getLocationDetails = async (locationId: string) => {
  const res = await api.get(`/admin-directory/v1/locations/${locationId}`);

  const location = res.data;
  const contacts = await api.get(`/admin-directory/v1/contacts/?location_id=${locationId}`);
  const data = { ...location, contacts: contacts.data.items };

  return data;
};

export const createProtocol = async (protocols: Protocol[], locationId: string) => {
  const request = protocols.map(async (protocol) => {
    const data = {
      location_id: locationId,
      name: protocol.name,
      documents: protocol.documents.map((doc) => doc.value),
      description: protocol.description,
      stages: protocol.stages,
    };

    await api.post("/admin-directory/v1/proceses/", data);
  });

  await Promise.all(request);
};

export const deleteContacts = async (id: string) => {
  await api.delete(`/admin-directory/v1/contacts/${id}`);
};

export const updateContact = async (data: ContactsSchema) => {
  const body = {
    name: data.name,
    description: data.description,
    phone: data.phone,
    role: data.role,
    email: data.email,
  };
  const res = await api.patch(`/admin-directory/v1/contacts/${data.id}`, body);

  return res.data;
};

export const deleteProtocol = async (id: string) => {
  await api.delete(`/admin-directory/v1/proceses/${id}`);
};

export const createStages = async (stages: Stages[], protocolId: string) => {
  const requests = stages.map(async (stage) => {
    if (!stage.previousExists) {
      const res = await api.post(`/admin-directory/v1/proceses/${protocolId}/stage/`, stage);
      return res.data;
    }
  });

  const data = await Promise.all(requests);
  return data.filter((item) => item !== undefined);
};

export const deleteStage = async (protocolId: string, stageId: number) => {
  await api.delete(`/admin-directory/v1/proceses/${protocolId}/stage/${stageId}`);
};
