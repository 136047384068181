import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DOCUMENTS_LIST, ExtendFormValues, FormValues } from "../../const";
import { createContactsBulk, createProtocol, createStages } from "../../services";
import { RootState } from "../store";
import { setCurrentPage, setTotalPages } from "../slices/location";
import { GetLocationsModel, Protocol, ProtocolDocument, Stages } from "../../models/location";
import { formatProtocolResponse } from "../../utils";

export const getLocations = createAsyncThunk("locations/getLocations", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const currentPage = state.locations.currentPage;

  const params: Record<string, string> = {};

  if (state.locations.serchTerm) {
    params["name"] = state.locations.serchTerm;
  }
  const res = await api.get(`/admin-directory/v1/locations/?size=10&page=${currentPage}`, {
    params,
  });

  thunkApi.dispatch(setTotalPages(res.data.pages));

  const locations = res.data.items;

  const tempData = locations.map(async (location: any) => {
    const contacts = await api.get(
      `/admin-directory/v1/contacts/?location_id=${location.id}&page=0`
    );
    const completeData = { ...location, contacts: contacts.data.items };

    return completeData;
  });

  const data = await Promise.all(tempData);

  return data;
});

export const createLocation = createAsyncThunk(
  "locations/createLocations",
  async (data: FormValues) => {
    const location = data.location;
    const contacts = data.contacts;
    const protocols = data.protocols;

    const resLocation = await api.post("/admin-directory/v1/locations/", location);

    if (resLocation.status === 200) {
      await createContactsBulk(contacts, resLocation.data.id);
      await createProtocol(protocols, resLocation.data.id);
    }
  }
);

export const getLocationDetail = createAsyncThunk(
  "locations/getLocationDetail",
  async (id: string) => {
    const location = await api.get(`/admin-directory/v1/locations/${id}`);

    const contacts = await api.get(
      `/admin-directory/v1/contacts/?location_id=${id}&page=0&size=25`
    );

    const protocols = await api.get(
      `/admin-directory/v1/proceses/?location_id=${id}&page=0&size=25`
    );

    const formatedProtocols = formatProtocolResponse(protocols.data.items);

    const data: FormValues = {
      contacts: contacts.data.items,
      location: location.data,
      protocols: formatedProtocols,
    };

    return data;
  }
);

export const updateLocation = createAsyncThunk(
  "locations/updateLocation",
  async ({ data, id }: any) => {
    const protocols = data.protocols;

    const temp = protocols.map(async (protocol: Protocol) => {
      const data: Record<string, any> = {
        name: protocol.name,
        description: protocol.description,
        documents: protocol.documents.map((doc) => doc.value),
      };
      if (protocol.id) {
        createStages(protocol.stages, protocol.id);
        const res = await api.patch(`/admin-directory/v1/proceses/${protocol.id}`, data);
        return res.data;
      } else {
        data["location_id"] = id;
        data["stages"] = protocol.stages;

        const res = await api.post(`/admin-directory/v1/proceses/`, data);
        return res.data;
      }
    });

    const newProtocols = await Promise.all(temp);

    return formatProtocolResponse(newProtocols);
  }
);
