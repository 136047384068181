import { AddCircleOutlineIcon, Box, Button, Grid, Typography, styled } from "@enerbit/base";
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormSetValue,
} from "react-hook-form";
import { ExtendFormValues } from "../../const";
import ProtocolStageItem from "../ProtocolStageItem/ProtocolStageItem";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { Protocol } from "../../models/location";
import { useEffect } from "react";

interface Props {
  protocolIndex: number;
  loading: boolean;
  control: Control<ExtendFormValues, any>;
  errors: FieldErrors<ExtendFormValues>;
  protocol: Protocol;
  stages: FieldArrayWithId<ExtendFormValues, `protocols.${number}.stages`, "_id">[];
  addStage: UseFieldArrayAppend<ExtendFormValues, `protocols.${number}.stages`>;
  removeStage: UseFieldArrayRemove;
  move: UseFieldArrayMove;
  updateStage: UseFieldArrayUpdate<ExtendFormValues, `protocols.${number}.stages`>;
  setValue: UseFormSetValue<ExtendFormValues>;
  isEdit?: boolean;
}

const SortTable = ({
  protocolIndex,
  loading,
  control,
  errors,
  protocol,
  stages,
  addStage,
  removeStage,
  setValue,
  isEdit,
}: Props) => {
  const handleDrag = (result: DropResult) => {
    if (!result.destination) return;

    const items = [...stages]; // Copia el array de etapas actual
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const reorderedItemsWithOrdering = items.map((item, index) => ({
      ...item,
      ordering: index + 1,
    }));

    setValue(`protocols.${protocolIndex}.stages`, reorderedItemsWithOrdering);
  };

  return (
    <Box>
      <Grid container columnSpacing={2} mt={2}>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={5.5}>
          <TableHeaders>
            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>Nombre</Typography>
          </TableHeaders>
        </Grid>
        <Grid item xs={5.5}>
          <TableHeaders>
            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>Descripción</Typography>
          </TableHeaders>
        </Grid>
        <Grid item xs={0.5}></Grid>
      </Grid>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId='stages'>
          {(droppableProvided) => (
            <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
              {stages
                .sort((a, b) => a.ordering - b.ordering)
                .map((stage, index) => (
                  <Draggable
                    key={stage.id}
                    draggableId={stage._id}
                    index={index}
                    isDragDisabled={
                      !isEdit || stages.every((stage) => !stage.previousExists) ? undefined : true
                    }>
                    {(draggableProvided) => (
                      <div
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.dragHandleProps}>
                        <ProtocolStageItem
                          control={control}
                          loading={loading}
                          errors={errors}
                          protocolIndex={protocolIndex}
                          provided={draggableProvided}
                          removeStage={removeStage}
                          key={stage.id}
                          stageIndex={index}
                          protocol={protocol}
                          stages={stages}
                          isEdit={isEdit}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        fullWidth
        variant='outlined'
        onClick={() =>
          addStage({
            id: stages.length + 1,
            description: "",
            name: "",
            ordering: stages.length + 1,
          })
        }
        color='secondary'
        sx={{ height: "41px", my: 3, display: "flex", alignItems: "center", gap: 1 }}>
        <AddCircleOutlineIcon />
        <Typography color={"secondary"} fontWeight={700}>
          Agregar paso
        </Typography>
      </Button>
    </Box>
  );
};

export default SortTable;

const TableHeaders = styled("div")`
  height: 41px;
  width: 100%;
  background-color: #f2f4f7;
  border-radius: 8px;
  padding: 12px, 14px, 12px, 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
