import {
  Button,
  CloseIcon,
  Grid,
  InputLabel,
  LoadingButton,
  Modal,
  TextField,
  Typography,
} from "@enerbit/base";
import { Box } from "@mui/system";
import { Control, Controller, UseFieldArrayUpdate, UseFormWatch } from "react-hook-form";
import { ExtendFormValues } from "../../const";
import { ContactsSchema } from "../../models/location";
import { useState } from "react";
import { updateContact } from "../../services";

interface Props {
  open: boolean;
  handleClose: () => void;
  control: Control<ExtendFormValues, any>;
  index: number | undefined;
  contacts: ContactsSchema[];
  watch: UseFormWatch<ExtendFormValues>;
  updateContact: UseFieldArrayUpdate<ExtendFormValues, "contacts">;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
  width: "550px",
};

const EditContactModal = ({
  open,
  handleClose,
  control,
  index,
  contacts,
  watch,
  updateContact: replaceContact,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onSave = async () => {
    if (index === undefined) return;

    setLoading(true);
    setError(false);

    const data = watch("contacts");

    try {
      const res = await updateContact(data[index]);
      replaceContact(index, res);
      handleClose();
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", my: 2 }}>
          <Typography sx={{ color: "#667085", fontSize: "18px" }}>Editar contacto</Typography>
          <Button onClick={handleClose} variant='outlined' className='back-btn'>
            <CloseIcon />
          </Button>
        </Box>
        {index !== undefined && (
          <Grid container columnSpacing={2} mt={2} rowSpacing={2}>
            <Grid item xs={12}>
              <InputLabel className='title-input-edition'>Nombre</InputLabel>
              <Controller
                control={control}
                name={`contacts.${index}.name`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={loading}
                    InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className='title-input-edition'>Teléfono</InputLabel>
              <Controller
                control={control}
                name={`contacts.${index}.phone`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={loading}
                    InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className='title-input-edition'>Correo electrónico</InputLabel>
              <Controller
                control={control}
                name={`contacts.${index}.email`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={loading}
                    InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className='title-input-edition'>Descripción</InputLabel>
              <Controller
                control={control}
                name={`contacts.${index}.description`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={loading}
                    InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className='title-input-edition'>Rol</InputLabel>
              <Controller
                control={control}
                name={`contacts.${index}.role`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={loading}
                    InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        <LoadingButton
          fullWidth
          loading={loading}
          variant='contained'
          color='secondary'
          sx={{ my: 2 }}
          onClick={onSave}>
          Guardar
        </LoadingButton>
      </Box>
    </Modal>
  );
};

export default EditContactModal;
