// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-input-edition {
  padding-bottom: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #344054 !important;
}

.back-btn {
  border-radius: 50% !important;
  height: 41px !important;
  width: 41px !important;
  min-width: unset !important;
}

.MuiPaper-root::before {
  background-color: unset !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/styles.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,6BAA6B;EAC7B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,uBAAuB;EACvB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".title-input-edition {\n  padding-bottom: 10px !important;\n  font-style: normal !important;\n  font-weight: 400 !important;\n  font-size: 14px !important;\n  line-height: 16px !important;\n  color: #344054 !important;\n}\n\n.back-btn {\n  border-radius: 50% !important;\n  height: 41px !important;\n  width: 41px !important;\n  min-width: unset !important;\n}\n\n.MuiPaper-root::before {\n  background-color: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
