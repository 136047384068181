import {
  ContactsSchema,
  Protocol,
  ProtocolDocument,
  SimpleContactsSchema,
  Stages,
} from "../models/location";

export type FormValues = {
  contacts: SimpleContactsSchema[];
  location: {
    name: string;
    description: string;
    address: string;
    longitude: number;
    latitude: number;
  };
  protocols: Protocol[];
};

export type ExtendFormValues = {
  contacts: ContactsSchema[];
  location: {
    name: string;
    description: string;
    address: string;
    longitude: number;
    latitude: number;
  };
  protocols: Protocol[];
};

export const contactObj: ContactsSchema = {
  description: "",
  name: "",
  phone: "",
  role: "",
  email: "",
};
export const protocolObj: Protocol = { name: "", description: "", stages: [], documents: [] };

export const DOCUMENTS_LIST: ProtocolDocument[] = [
  { name: "Afiliación seguridad social", value: "affiliation_social_security" },
  { name: "Formulario pagos de seguridad social", value: "social_security_payment_form" },
  { name: "Certificado de trabajo de alturas", value: "safe_work_height" },
];

export type ContactsForm = {
  contacts: ContactsSchema[];
};

export const CONTACTS_TABLE_HEADERS: string[] = [
  "Nombre",
  "Celular",
  "Detalle - Rol",
  "Correo",
  "Descripción",
  "Fecha de creación",
];
