import { HashRouter, Route, Routes } from "react-router-dom";
import LocationsList from "../pages/LocationsList/LocationsList";
import LocationDetail from "../pages/LocationDetail/LocationDetail";
import CreateLocation from "../pages/CreateLocation/CreateLocation";

const Router = () => {
  return (
    <HashRouter basename='/admins-directory'>
      <Routes>
        <Route path='/' element={<LocationsList />} />
        <Route path='detail/:id' element={<LocationDetail />} />
        <Route path='create' element={<CreateLocation />} />
      </Routes>
    </HashRouter>
  );
};

export default Router;
