import {
  Button,
  Grid,
  TextField,
  DeleteOutlineIcon,
  Box,
  FormControl,
  FormHelperText,
} from "@enerbit/base";
import { Control, Controller, FieldErrors, UseFieldArrayRemove } from "react-hook-form";
import { ExtendFormValues, FormValues } from "../../const";

interface Props {
  index: number;
  loading: boolean;
  control: Control<ExtendFormValues, any>;
  removeContact: () => void;
  errors: FieldErrors<ExtendFormValues>;
}

const ContactItem = ({ index, loading, control, removeContact, errors }: Props) => {
  return (
    <Grid container columnSpacing={2} mt={2}>
      <Grid item xs={2.2}>
        <FormControl fullWidth>
          <Controller
            control={control}
            name={`contacts.${index}.name`}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={loading}
                error={!!errors.contacts && !!errors.contacts[index]?.name}
                InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                fullWidth
              />
            )}
          />
          {!!errors.contacts && !!errors.contacts[index]?.name && (
            <FormHelperText error>{errors.contacts[index]?.name?.message}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={2.2}>
        <FormControl fullWidth>
          <Controller
            control={control}
            name={`contacts.${index}.phone`}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={loading}
                error={!!errors.contacts && !!errors.contacts[index]?.phone}
                InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                fullWidth
              />
            )}
          />
          {!!errors.contacts && !!errors.contacts[index]?.phone && (
            <FormHelperText error>{errors.contacts[index]?.phone?.message}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={2.2}>
        <FormControl fullWidth>
          <Controller
            control={control}
            name={`contacts.${index}.email`}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={loading}
                error={!!errors.contacts && !!errors.contacts[index]?.email}
                InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                fullWidth
              />
            )}
          />
          {!!errors.contacts && !!errors.contacts[index]?.email && (
            <FormHelperText error>{errors.contacts[index]?.email?.message}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={2.2}>
        <FormControl fullWidth>
          <Controller
            control={control}
            name={`contacts.${index}.description`}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={loading}
                error={!!errors.contacts && !!errors.contacts[index]?.description}
                InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                fullWidth
              />
            )}
          />
          {!!errors.contacts && !!errors.contacts[index]?.description && (
            <FormHelperText error>{errors.contacts[index]?.description?.message}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={2.2}>
        <FormControl fullWidth>
          <Controller
            control={control}
            name={`contacts.${index}.role`}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={loading}
                error={!!errors.contacts && !!errors.contacts[index]?.role}
                InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                fullWidth
              />
            )}
          />
          {!!errors.contacts && !!errors.contacts[index]?.role && (
            <FormHelperText error>{errors.contacts[index]?.name?.message}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={0.8}>
        <Button
          disabled={loading}
          fullWidth
          onClick={removeContact}
          sx={{ backgroundColor: "#FEECEB", width: "50px", height: "50px", minWidth: "unset" }}>
          <DeleteOutlineIcon color='error' />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContactItem;
