import { Box, Button, CustomAlert, LoadingButton, Modal, Typography } from "@enerbit/base";
import { useState } from "react";

interface Props {
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  title: string;
  errorMessage: string;
  loading: boolean;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
  width: "450px",
};

const ConfirmationModal = ({
  open,
  handleClose,
  onConfirm,
  title,
  errorMessage,
  loading,
  error,
  setError
}: Props) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", textAlign: "center", color: "#3D4350", fontWeight: 700 }}>
            {title}
          </Typography>
          {error && (
            <CustomAlert severity='error' onClose={() => setError(false)} text={errorMessage} />
          )}

          <Box sx={{ display: "flex", gap: 2, my: 2 }}>
            <Button
              fullWidth
              color='secondary'
              variant='outlined'
              onClick={handleClose}
              disabled={loading}>
              Cancelar
            </Button>
            <LoadingButton
              fullWidth
              color='secondary'
              variant='contained'
              onClick={onConfirm}
              loading={loading}>
              Confirmar
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
