// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .MuiTableRow-root th {
  background-color: #f2f4f7 !important;
  padding: 8px 16px !important;
  color: #667085 !important;
  font-size: 14px;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.MuiTableCell-root {
  border-bottom: unset !important;
}

.MuiInputBase-root {
  background-color: white;
  border-radius: 12px !important;
}

.MuiTableRow-root:nth-child(even) {
  background-color: #efe9fb;
} */

/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: unset;
  border-radius: 0px 30px 30px 0px;
  height: 30px;
} */
`, "",{"version":3,"sources":["webpack://./src/components/ContactsTable/ContactsTable.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;GAsBG;;AAEH;;;;;;;;GAQG","sourcesContent":["/* .MuiTableRow-root th {\n  background-color: #f2f4f7 !important;\n  padding: 8px 16px !important;\n  color: #667085 !important;\n  font-size: 14px;\n}\n\n.MuiPaper-root {\n  box-shadow: none !important;\n}\n\n.MuiTableCell-root {\n  border-bottom: unset !important;\n}\n\n.MuiInputBase-root {\n  background-color: white;\n  border-radius: 12px !important;\n}\n\n.MuiTableRow-root:nth-child(even) {\n  background-color: #efe9fb;\n} */\n\n/* ::-webkit-scrollbar {\n  width: 10px;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: unset;\n  border-radius: 0px 30px 30px 0px;\n  height: 30px;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
