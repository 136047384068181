import { Slice, createSlice } from "@reduxjs/toolkit";
import { FormValuesSchema, LocationSchema, LocationsState } from "../../models/location";
import { createLocation, getLocationDetail, getLocations } from "../thunks/locations";
import { FormValues } from "../../const";
import { enqueueSnackbar } from "notistack";

const initialState: LocationsState = {
  loading: false,
  error: false,
  success: false,
  locations: [],
  locationDetail: {} as FormValues,
  totalPages: 0,
  currentPage: 0,
  serchTerm: "",
};

export const locationsSlice: Slice<LocationsState> = createSlice({
  name: "locationss",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setSuccess: (state, { payload }) => {
      state.success = payload;
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    setTotalPages(state, { payload }) {
      state.totalPages = payload;
    },
    setSearchTerm(state, { payload }) {
      state.serchTerm = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getLocations.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.locations = payload;
    });
    builder.addCase(getLocations.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(createLocation.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(createLocation.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      enqueueSnackbar("Locación creada con éxito.", { variant: "success" });
    });
    builder.addCase(createLocation.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      enqueueSnackbar("Error al crear la locación.", { variant: "error" });
    });
    builder.addCase(getLocationDetail.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getLocationDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.locationDetail = payload;
    });
    builder.addCase(getLocationDetail.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { setError, setCurrentPage, setTotalPages, setSuccess, setSearchTerm } =
  locationsSlice.actions;
