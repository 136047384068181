import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  DeleteOutlineOutlinedIcon,
  ExpandMoreIcon,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
  styled,
} from "@enerbit/base";
import { SyntheticEvent, useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFieldArrayRemove,
  UseFormSetValue,
  useFieldArray,
} from "react-hook-form";
import { ExtendFormValues, DOCUMENTS_LIST } from "../../const";
import SortTable from "../SortTable/SortTable";
import { Protocol, ProtocolDocument } from "../../models/location";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { deleteProtocol } from "../../services";

interface Props {
  index: number;
  loading: boolean;
  control: Control<ExtendFormValues, any>;
  removeProtocol: UseFieldArrayRemove;
  protocol: Protocol;
  setValue: UseFormSetValue<ExtendFormValues>;
  errors: FieldErrors<ExtendFormValues>;
  isEdit?: boolean;
}

const ProtocolItem = ({
  index,
  loading,
  removeProtocol,
  control,
  protocol,
  setValue,
  errors,
  isEdit,
}: Props) => {
  const [expanded, setExpanded] = useState<number | null>(index);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const {
    fields: documents,
    append: addDocuments,
    remove: removeDocuments,
  } = useFieldArray({ name: `protocols.${index}.documents`, control });

  const {
    fields: stages,
    append: addStage,
    remove: removeStage,
    move,
    update: updateStage,
  } = useFieldArray({ name: `protocols.${index}.stages`, control, keyName: "_id" });

  const handleChange = (index: number) => (_: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? index : null);
  };

  const accordionSummaryStyles = {
    background: "#efe9fb",
    borderRadius: expanded === index ? "16px 16px 0 0" : "16px",
    height: "60px",
    px: 2,
    py: 1,
  };

  const accordionDetailsStyles = {
    p: 2,
    border: expanded === index ? `1px solid #e4e7ec` : null,
    borderRadius: "0 0 16px 16px",
  };

  const onDeleteProtocol = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (stages.length > 0) {
      setWarningModal(true);
    } else {
      setDeleteModal(true);
    }
  };

  const onConfirmDelete = async () => {
    if (protocol.id) {
      try {
        setLoadingDelete(true);
        setError(false);
        await deleteProtocol(protocol.id);
        removeProtocol(index);
        setDeleteModal(false);
      } catch (error) {
        setError(true);
      } finally {
        setLoadingDelete(false);
      }
    }
  };

  const onHandleCheck = (event: React.ChangeEvent<HTMLInputElement>, doc: ProtocolDocument) => {
    if (event.target.checked && !documents.some((item) => item.value === doc.value)) {
      addDocuments(doc);
    } else {
      const index = documents.findIndex((item) => item.value === doc.value);
      removeDocuments(index);
    }
  };

  return (
    <>
      <Accordion
        expanded={expanded === index}
        onChange={handleChange(index)}
        sx={{ my: 2, boxShadow: "unset !important" }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                fontSize: "25px",
              }}
            />
          }
          sx={accordionSummaryStyles}>
          <AccordionContent>
            <Button color='error' variant='contained' size='small' onClick={onDeleteProtocol}>
              <DeleteOutlineOutlinedIcon />
            </Button>
            <Box display={"flex"}>
              <Typography color={"primary"} sx={{ fontWeight: 700 }}>
                Protocolo # {index + 1}
              </Typography>
            </Box>
            <Box />
          </AccordionContent>
        </AccordionSummary>
        <AccordionDetails sx={accordionDetailsStyles}>
          <Grid container rowSpacing={2} sx={{ marginTop: "3px" }}>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#3D4350" }} required>
                Nombre
              </InputLabel>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`protocols.${index}.name`}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={loading}
                      error={!!errors.protocols && !!errors.protocols[index]?.name}
                      InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                      fullWidth
                    />
                  )}
                />
                {!!errors.protocols && !!errors.protocols[index]?.name && (
                  <FormHelperText error>{errors.protocols[index]?.name?.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#3D4350" }}>Descripción</InputLabel>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={`protocols.${index}.description`}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={loading}
                      multiline
                      error={!!errors.protocols && !!errors.protocols[index]?.description}
                      rows={3}
                      InputProps={{ sx: { borderRadius: "12px", outline: "none" } }}
                      fullWidth
                    />
                  )}
                />
                {!!errors.protocols && !!errors.protocols[index]?.description && (
                  <FormHelperText error>
                    {errors.protocols[index]?.description?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Box my={2}>
            <Typography sx={{ color: "#667085", fontSize: "18px" }}>
              Pasos a seguir del protocolo
            </Typography>
            <SortTable
              control={control}
              loading={loading}
              protocolIndex={index}
              errors={errors}
              protocol={protocol}
              addStage={addStage}
              removeStage={removeStage}
              move={move}
              stages={stages}
              updateStage={updateStage}
              setValue={setValue}
              isEdit={isEdit}
            />
          </Box>
          <Box my={2}>
            <Typography sx={{ color: "#667085", fontSize: "18px" }}>Documentación</Typography>
          </Box>
          <Grid container rowSpacing={2}>
            {DOCUMENTS_LIST.map((doc) => (
              <Grid key={doc.value} item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='secondary'
                      value={doc.value}
                      onChange={(event) => onHandleCheck(event, doc)}
                      checked={documents.some((item) => item.value === doc.value)}
                    />
                  }
                  label={doc.name}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <ConfirmationModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        onConfirm={() => (protocol.id ? onConfirmDelete() : removeProtocol(index))}
        title='¿Seguro que deseas eliminar este protocolo? Esta acción no se puede deshacer'
        errorMessage='Error eliminando el protocolo'
        loading={loadingDelete}
        error={error}
        setError={setError}
      />
      <ConfirmationModal
        open={warningModal}
        handleClose={() => setWarningModal(false)}
        onConfirm={() => setWarningModal(false)}
        title='No es posible eliminar. Hay pasos asociados al protocolo.'
        errorMessage='Error eliminando el protocolo'
        loading={loadingDelete}
        error={error}
        setError={setError}
      />
    </>
  );
};

export default ProtocolItem;

const AccordionContent = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
