import {
  ArrowForwardIcon,
  Box,
  Button,
  FormControl,
  SearchIcon,
  TextField,
  Typography,
  styled,
  AddIcon,
  CachedIcon,
} from "@enerbit/base";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocations } from "../../store/thunks/locations";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { setCurrentPage, setSearchTerm } from "../../store/slices/location";

interface Props {
  isDetail?: boolean;
}

const Header = ({ isDetail }: Props) => {
  const navigate = useNavigate();
  const searchTerm = useSelector((state: RootState) => state.locations.serchTerm);
  const dispacth = useDispatch<AppDispatch>();

  const handleSearch = async () => {
    dispacth(setCurrentPage(0));
    await dispacth(getLocations());
  };

  const onRefresh = async () => {
    dispacth(setSearchTerm(""));
    dispacth(setCurrentPage(0));
    dispacth(getLocations());
  };

  return (
    <HeaderContainer>
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
        {isDetail && (
          <Box
            sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={() => navigate("/")}>
            <ArrowForwardIcon
              style={{ transform: "rotate(180deg)", fontSize: "35px" }}
              color='primary'
            />
          </Box>
        )}
        <Typography color={"primary"} sx={{ fontWeight: 700, fontSize: "28px" }}>
          {isDetail ? "Detalle" : "Directorio"}
        </Typography>
      </Box>
      {!isDetail && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
          <FormControl sx={{ width: "630px" }}>
            <Box sx={{ display: "flex" }}>
              <TextField
                value={searchTerm}
                onChange={(e) => dispacth(setSearchTerm(e.target.value))}
                placeholder='Buscar'
                sx={{ width: "90%" }}
                InputProps={{
                  sx: {
                    "& fieldset": {
                      borderRadius: "12px 0px 0px 12px !important",
                    },
                  },
                }}
              />
              <Button
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "0px 12px 12px 0px !important",
                }}
                onClick={handleSearch}
                variant='contained'
                color='primary'>
                <Typography>
                  <SearchIcon />
                </Typography>
              </Button>
            </Box>
          </FormControl>
          <Button
            sx={{
              borderRadius: "12px !important",
              height: "50px",
              width: "20px",
            }}
            fullWidth
            onClick={onRefresh}
            variant='contained'
            color='primary'>
            <CachedIcon />
          </Button>
          <Button
            onClick={() => navigate("/create")}
            sx={{ height: "50px" }}
            variant='contained'
            color='secondary'>
            <AddIcon />
            Crear
          </Button>
        </Box>
      )}
    </HeaderContainer>
  );
};

export default Header;

interface HeaderContainerProps {
  isDetail?: boolean;
}

const HeaderContainer = styled("div")<HeaderContainerProps>(({ isDetail }) => ({
  display: "flex",
  justifyContent: isDetail ? "normal" : "space-between",
  alignItems: "center",
  gap: "20px",
}));
